//@author: Mandy
import { jSith } from "../../util/jquery-replacement";
import { S25Util } from "../../util/s25-util";
import { EventService } from "../../services/event.service";

declare global {
    interface Window {
        angBridge: any;
    }
}
export class HierarchyUtil {
    public static decorateItems(list: any, filter?: any, sort?: any, parentId?: any) {
        list = S25Util.array.forceArray(list);
        let items = list;
        if (parentId) {
            items = items.filter((i: any) => i.parent_id === parentId);
        }
        items = items.filter((i: any) => i.node_type === filter);
        items = items.map((obj: any) => {
            return { itemName: obj.type_name, itemId: obj.type_id };
        });
        items.sort((a: any, b: any) => a[sort] - b[sort]);
        return items;
    }

    public static decorateSelectedItems(list: any, idProp: any, nameProp: any) {
        let selectedItems: any = [];
        let perm = false;

        if (S25Util.array.isArray(list)) {
            jSith.forEach(list, (key, c) => {
                c.role_id != -2 && c.role_id != -1 ? (perm = false) : (perm = true);
                selectedItems.push({
                    itemId: c[idProp],
                    itemName: c[nameProp],
                    isPermanent: perm,
                });
            });
            return selectedItems;
        } else {
            selectedItems.push({
                itemId: list[idProp],
                itemName: list[nameProp],
                isPermanent: perm,
            });
            return selectedItems;
        }
    }

    public static decorateItemsToSave(
        list: any,
        initList: any,
        removeList: any,
        idProp: any,
        nameProp: any,
        isEdit: boolean,
        optProp?: any,
        optKey?: any,
    ) {
        let selectedItems: any = [];
        let removeItems: any = [];
        let status = "est";
        jSith.forEach(list, (key, c) => {
            //!S25Util.array.isIn(initList, "itemId", c.itemId) ?  status = "new" : '';
            let find = S25Util.array.getByProp(initList, "itemId", c.itemId);
            find === null ? (status = "new") : (status = "est");
            selectedItems.push({
                [idProp]: c.itemId,
                [nameProp]: c.itemName,
                status: status,
                [optProp]: optKey,
            });
        });

        jSith.forEach(removeList, (key, c) => {
            removeItems.push({
                [idProp]: c.itemId,
                [nameProp]: c.itemName,
                status: "del",
                [optProp]: optKey,
            });
        });

        isEdit ? (selectedItems = selectedItems.concat(removeItems)) : ""; // if add new eventType/cabinet/folder. no need to included del items
        return selectedItems;
        //return selectedItems.concat(removeItems);
    }

    public static groupBy(list: any, keyGetter: any) {
        const map = new Map();
        list.forEach((item: any) => {
            const key = keyGetter(item);
            const collection = map.get(key);
            if (!collection) {
                map.set(key, [item]);
            } else {
                collection.push(item);
            }
        });
        return map;
    }

    public static listToTree(data: any, options: any) {
        options = options || {};
        let idKey = options.idKey || "type_id";
        let parentKey = options.parentKey || "parent_id";
        let childrenKey = options.childrenKey || "folder";

        let map: any = {};
        for (let i = 0; i < data.length; i++) {
            // make cache
            if (data[i][idKey]) {
                map[data[i][idKey]] = data[i];
                data[i][childrenKey] = [];
            }
        }
        for (let i = 0; i < data.length; i++) {
            if (data[i][parentKey]) {
                // is a child
                if (map[data[i][parentKey]]) {
                    // for dirty data
                    map[data[i][parentKey]][childrenKey].push(data[i]); // add child to parent
                    data.splice(i, 1); // remove from root
                    i--; // iterator correction
                } else {
                    // data[i][parentKey] = 0; // normally clean dirty data, but 25Live cabinet parentId is 0
                }
            }
        }
        return data;
    }

    public static getItems(data: any, items: any, index: any) {
        data.forEach((x: any) => {
            if (!items) {
                items = [];
            }
            items.push(x);
            items[items.length - 1].index = index;
            if (x.folder && x.expanded) {
                this.getItems(x.folder, items, index + 1);
            }
        });
        return items;
    }

    public static inheritanceRules() {
        let defaultIeritanceRules = [
            {
                local_edit: "T",
                show_data: "T",
                override: "F",
                inherit_item: "Accounts",
                inherit_parent: "F",
                inherit_rule: "TFFT",
                status: "new",
                vcal: "",
            },
            {
                local_edit: "T",
                show_data: "T",
                override: "T",
                inherit_item: "Category",
                inherit_parent: "T",
                inherit_rule: "TTTT",
                status: "new",
                vcal: "",
            },
            {
                local_edit: "T",
                show_data: "T",
                override: "F",
                inherit_item: "CustAtrb",
                inherit_parent: "F",
                inherit_rule: "TFFT",
                status: "new",
                vcal: "",
            },
            {
                local_edit: "T",
                show_data: "T",
                override: "F",
                inherit_item: "Space",
                inherit_parent: "F",
                inherit_rule: "TFFT",
                status: "new",
                vcal: "",
            },
            {
                local_edit: "T",
                show_data: "T",
                override: "T",
                inherit_item: "TimeCons",
                inherit_parent: "T",
                inherit_rule: "TTTT",
                status: "new",
                vcal: "",
            },
            {
                local_edit: "",
                show_data: "",
                override: "",
                inherit_item: "Vcal",
                inherit_parent: "",
                inherit_rule: "U",
                status: "new",
                vcal: "U",
            },
        ];
        return defaultIeritanceRules;
    }

    public static getUrlParam() {
        let getRefInfo: any = {};
        let getHref: any = window.location.href;
        getHref = getHref.split("/");
        let getUrlTypeId = getHref[getHref.length - 1];
        let getUrlType = getHref[getHref.length - 2];
        let getUrlMode = getHref[getHref.length - 3];
        let getUrlItemTypeId = getHref[getHref.length - 4];
        let getUrlDate = getHref[getHref.length - 5];
        getRefInfo = {
            href: getHref,
            getUrlTypeId: getUrlTypeId,
            getUrlType: getUrlType,
            getUrlMode: getUrlMode,
            getUrlItemTypeId: getUrlItemTypeId,
            getUrlDate: getUrlDate,
        };
        //console.log({path:  getHref, getUrlTypeId: getUrlTypeId, getUrlType: getUrlType, getUrlMode: getUrlMode});
        return getRefInfo;
    }

    public static findPath(children: any, targetId: any, idsOnly?: any, idKey?: any) {
        const path: any = [];
        (function search(children) {
            return children?.some((child: any) => {
                idsOnly ? path.push(child[idKey]) : path.push({ ...child });
                if (child[idKey] === targetId || search(child.folder)) {
                    return true;
                }
                path.pop();
            });
        })(children);
        //console.log({pathUtil: path});
        return path;
    }

    public static getCompUrl(comp: any, date?: any): string {
        //let url : string = S25Util.getWindowOrigin() + S25Util.getWindowPath();
        //console.log({stateservice: window.angBridge.$injector.get('StateMap[eventTypeHierarchy]')});
        // tried to get url more dynamic to, looks like state services need to migration first, so far state service migrationed couple functions only

        !date ? (date = 1) : "";

        let url = "";
        switch (comp) {
            // 1 = eventType, 2 = cabinet, 3 = folder
            case "hierarchy":
                url = "#!/home/system/settings/master/definitions/event/types/hierarchy/" + date;
                break;
            case "eventTypeList":
                url = "#!/home/system/settings/master/definitions/event/types/list";
                break;
            case "eventType":
                url = "#!/home/system/settings/master/definitions/event/types/hierarchy/" + date + "/1";
                break;
            case "cabinet":
                url = "#!/home/system/settings/master/definitions/event/types/hierarchy/" + date + "/2";
                break;
            case "folder":
                url = "#!/home/system/settings/master/definitions/event/types/hierarchy/" + date + "/3";
                break;
            case "cabinetAdvForm":
                url = "#!/home/system/settings/master/definitions/event/types/form/2";
                break;
            case "folderAdvForm":
                url = "#!/home/system/settings/master/definitions/event/types/form/3";
                break;
            default:
        }
        return url;
    }
}

export const S25EventTypesMap: any = {
    1: {
        name: {
            singular: "Event Type",
            plural: "Event Types",
        },
        item: {
            name: "type_name",
            id: "type_id",
            parentId: "parent_id",
            state: "defn_state",
            type: "node_type",
        },
        service: {
            getItems: EventService.getAllEventType,
        },
        url: {
            eventType: "",
            cabinet: "",
            folder: "",
        },
        idKey: "type_id",
        sort: "node_type",
        parentKey: "parent_id",
    },
    2: {
        name: {
            singular: "Cabinet Type",
            plural: "Cabinet Types",
        },
        item: {
            name: "event_name",
            id: "event_id",
            parentId: "parent_id",
            parentName: "parent_name",
            state: "state",
            type: "node_type",
            cabinetId: "cabinet_id",
        },
        service: {
            getItems: EventService.getNodeTypeCabinet,
        },
        url: {
            eventType: "",
            cabinet: "",
            folder: "",
        },
        idKey: "event_type_id",
        sort: "event_name",
        parentKey: "event_type_id",
    },
    3: {
        name: {
            singular: "Folder Type",
            plural: "Folder Types",
        },
        item: {
            name: "event_name",
            id: "event_id",
            parentId: "parent_id",
            parentName: "parent_name",
            state: "state",
            type: "node_type",
            cabinetId: "cabinet_id",
        },
        service: {
            getItems: EventService.getNodeTypeFolder,
        },
        url: {
            eventType: "",
            cabinet: "",
            folder: "",
        },
        idKey: "event_id",
        sort: "event_name",
        parentKey: "parent_id",
    },
};
