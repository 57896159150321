//@author: mandy

import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    NgZone,
    OnInit,
    Output,
    ViewEncapsulation,
} from "@angular/core";
import { TypeManagerDecorator } from "../../main/type.map.service";

@TypeManagerDecorator("s25-ng-event-type-delete")
@Component({
    selector: "s25-ng-event-type-delete",
    template: `
        @if (init) {
            <div class="c-margin-top--half   ngBold cn-alert cn-alert--warning ">
                <div class="c-margin-top--half c-margin-bottom--half">{{ this.warningMsg }}</div>
                <div class="c-displayBlock c-margin-top--half  c-margin-left--half">
                    <button
                        class="aw-button aw-button--primary c-margin-top--half c-margin-left--half"
                        (click)="confirm('confirmed')"
                    >
                        Confirmed
                    </button>
                    <button
                        class="aw-button aw-button--outline c-margin-top--half c-margin-left--quarter"
                        (click)="confirm('cancel')"
                    >
                        Cancel
                    </button>
                </div>
            </div>
        }
    `,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.Default,
})
export class S25EventTypeDeleteComponent implements OnInit {
    init = false;
    @Input() warningMsg?: string = "Delete action is permanent and will result in loss of information.";
    @Output() modelValueChange = new EventEmitter();

    constructor(
        private elementRef: ElementRef,
        private cd: ChangeDetectorRef,
        private zone: NgZone,
    ) {}

    ngOnInit(): void {
        this.init = true;
    }

    confirm(e: any) {
        this.modelValueChange.emit(e);
    }
}
