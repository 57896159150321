//@author: mandy

import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    NgZone,
    OnDestroy,
    OnInit,
    Renderer2,
    ViewEncapsulation,
    ViewRef,
} from "@angular/core";
import { Location } from "@angular/common";
import { EventService } from "../../services/event.service";
import { S25Util } from "../../util/s25-util";
import { TypeManagerDecorator } from "../../main/type.map.service";
import { HierarchyUtil, S25EventTypesMap } from "./s25.event.type.hierarchy.util";
import { CabinetFolderApi } from "./s25.cabinet.folder.component";
import { EventTypeApi } from "./s25.event.type.component";
import { Api } from "../../api/api";
//TODO: figure out why loading-inline is causing error on prod-all compile, commented out for now
import { S25LoadingApi } from "../s25-loading/loading.api";

export class HierarchyApi extends Api {
    static refresh(relativeElem: HTMLElement) {
        //Refreshes the parent list view of event type hierarchy
        return Api.callApiFn(
            relativeElem,
            "s25-ng-event-type-hierarchy",
            null,
            null,
            (comp: S25EventTypeHierarchyComponent) => {
                comp.type = "view";
                comp && comp.refresh();
                // TODO: I don't think this code is being reached??
            },
        );
    }

    //ANG-3744 load a new eventType for editing if needed && ANG-3863 reload issue
    static switch(relativeElem: HTMLElement) {
        return Api.callApiFn(
            relativeElem,
            "s25-ng-event-type-hierarchy",
            null,
            null,
            (comp: S25EventTypeHierarchyComponent) => {
                let data = HierarchyUtil.getUrlParam();
                let itemId = data.getUrlTypeId && parseInt(data.getUrlTypeId);
                let mode = data.getUrlMode;
                if ((comp && !!comp.itemId && comp.itemId != itemId) || (comp && !!comp.mode && comp.mode != mode)) {
                    comp.ngOnInit();
                }
            },
        );
    }
}

@TypeManagerDecorator("s25-ng-event-type-hierarchy")
@Component({
    selector: "s25-ng-event-type-hierarchy",
    template: `
        @if (!this.bulkEditAction) {
            <div>
                <div class="tab-panel">
                    @for (type of this.dropdownTypes; track type) {
                        <button
                            class="btn btn-default ngCompview"
                            [ngClass]="{
                                'btn-primary': type.itemId === itemTypeId,
                                'event-tab': type.itemId === 1,
                                'folder-tab': type.itemId === 3,
                            }"
                            (click)="goToHierarchyType(type)"
                        >
                            {{ type.itemName }}
                        </button>
                    }
                </div>
                <br />
                <div class="c-margin-bottom--half">
                    @if (!this.resetType) {
                        <h2 class="c-margin-bottom--half">
                            {{ this.itemTypeId === 1 ? "Event Type" : this.itemTypeId === 2 ? "Cabinet" : "Folder" }}
                            Hierarchy
                        </h2>
                    }
                </div>
                @if (this.type === "view" && this.itemTypeId === 1) {
                    <div class="c-margin-bottom--half c-margin-top--single">
                        <label class="ngBold ngBlock" for="createNew">Create a New Type</label>
                        <div class="master-defs--action-wrapper">
                            <select
                                class="cn-form__control"
                                name="createNew"
                                (change)="this.createNew(this.itemTypeId, $event)"
                            >
                                <option value="view" selected></option>
                                <option value="C">Cabinet Type</option>
                                <option value="F">Folder Type</option>
                                <option value="E">Event Type</option>
                            </select>
                        </div>
                    </div>
                }
                @if (this.type === "view") {
                    <div class="c-margin-bottom--half">
                        @if (this.itemTypeId === 1) {
                            <span class="ngBold ngBlock c-margin-bottom--half c-margin-top--half"
                                >Hierarchy of Types</span
                            >
                        }
                        <div>
                            @if (this.type === "view") {
                                <div>
                                    @if (this.itemTypeId === 3) {
                                        <span>
                                            <select
                                                class="cn-form__control c-margin-top--quarter"
                                                (change)="changeDataDate($event)"
                                            >
                                                @for (m of this.modeDates; track m) {
                                                    <option
                                                        [ngValue]="m.itemId"
                                                        [selected]="m.itemId === this.selectedDate"
                                                    >
                                                        {{ m.itemName }}
                                                    </option>
                                                }
                                            </select>
                                        </span>
                                    }
                                </div>
                            }
                            @if (this.type === "view" && (this.itemTypeId === 2 || this.itemTypeId === 3)) {
                                <button
                                    class="aw-button aw-button--primary c-margin-right--half c-margin-top--single"
                                    (click)="this.createNew(this.itemTypeId)"
                                >
                                    Create New {{ this.itemTypeId === 2 ? "Cabinet" : "Folder" }}
                                </button>
                            }
                            @if (!this.expandedAll) {
                                <button
                                    class="aw-button aw-button--outline c-margin-right--single"
                                    (click)="this.expandAll()"
                                >
                                    Expand All
                                </button>
                            }
                            @if (this.expandedAll) {
                                <button
                                    class="aw-button aw-button--outline c-margin-right--single"
                                    (click)="this.collapseAll()"
                                >
                                    Collapse All
                                </button>
                            }
                            @if (this.init) {
                                <button
                                    class="aw-button aw-button--outline c-margin-right--single"
                                    [disabled]="!this.bulkEditItems.length || this.bulkEditItems.length === 0"
                                    (click)="gotToBulkEdit()"
                                >
                                    Bulk Edit
                                </button>
                            }
                            <button
                                (click)="this.refresh()"
                                aria-label="Refresh"
                                role="button"
                                tabindex="0"
                                class="btn btn-flat btn-icon refreshButton ng-scope c-margin-right--double"
                            >
                                <svg class="c-svgIcon" role="img">
                                    <title>Refresh</title>
                                    <use
                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                        xlink:href="./resources/typescript/assets/css-compiled/images/sprite.svg#refresh"
                                    ></use>
                                </svg>
                            </button>
                        </div>
                    </div>
                }
                <s25-loading-inline model="{}" class="c-margin-top--single"></s25-loading-inline>
                @if (this.init && this.listToFolderTreeInit) {
                    <div class="c-margin-top--half">
                        <div class="c-margin-top--single">
                            @switch (type) {
                                @case ("view") {
                                    <div class="edit-note">
                                        Note: Shift + click to select multiple
                                        {{
                                            itemTypeId === 1
                                                ? "event types"
                                                : itemTypeId === 2
                                                  ? "cabinets"
                                                  : "folders"
                                        }}. If you bulk edit multiple
                                        {{
                                            itemTypeId === 1
                                                ? "event types"
                                                : itemTypeId === 2
                                                  ? "cabinets"
                                                  : "folders"
                                        }}, any actions you take will be applied to all selected
                                        {{
                                            itemTypeId === 1
                                                ? "event types"
                                                : itemTypeId === 2
                                                  ? "cabinets"
                                                  : "folders"
                                        }}.
                                    </div>
                                    @for (item of items; track item; let idx = $index) {
                                        <div>
                                            <div [style.padding-left]="item.index * 20 + 'px'">
                                                <div>
                                                    @switch (item.node_type) {
                                                        @case ("C") {
                                                            <div
                                                                class="hierarchy--wrapper"
                                                                (mouseover)="showEditOnHover(idx)"
                                                                (mouseleave)="removeEditOnHover(idx, item.expanded)"
                                                                (focusin)="showEditOnHover(idx)"
                                                                (focusout)="removeEditOnHover(idx, item.expanded)"
                                                                [attr.aria-label]="item.type_name"
                                                                tabindex="0"
                                                            >
                                                                @if (
                                                                    item.uid.indexOf("level3_") > -1 &&
                                                                    this.orgItemTypeId === 2
                                                                ) {
                                                                    <span>
                                                                        <s25-ng-shift-selectable-checkbox
                                                                            [id]="'item-' + idx"
                                                                            [index]="idx"
                                                                            [group]="'cabinet'"
                                                                            [modelValue]="false"
                                                                            (modelValueChange)="
                                                                                this.onCheckBoxChange(item, $event)
                                                                            "
                                                                        ></s25-ng-shift-selectable-checkbox>
                                                                    </span>
                                                                }
                                                                <p
                                                                    class="buttonText"
                                                                    id="textFocus{{ item.type_id }}"
                                                                    tabindex="0"
                                                                    (click)="this.expanded(item)"
                                                                    (keydown.enter)="expanded(item)"
                                                                >
                                                                    <span class="svg--icon">
                                                                        @if (item.parent_id === 0) {
                                                                            <svg class="c-svgIcon" role="img">
                                                                                <title>Cabinet</title>
                                                                                <use
                                                                                    xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                                    xlink:href="./resources/typescript/assets/css-compiled/images/sprite.svg#event_structure--cabinet"
                                                                                ></use>
                                                                            </svg>
                                                                        }
                                                                        @if (item.parent_id !== 0) {
                                                                            <svg class="c-svgIcon" role="img">
                                                                                <title>Cabinet</title>
                                                                                <use
                                                                                    xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                                    xlink:href="./resources/typescript/assets/css-compiled/images/sprite.svg#event_structure--cabinet-open"
                                                                                ></use>
                                                                            </svg>
                                                                        }
                                                                    </span>
                                                                    <span>{{ item.type_name }}</span>
                                                                    @if (item.defn_state === 0 && !item.cabinet_id) {
                                                                        <span class="not_active c-margin-left--half"
                                                                            >[Not Active]
                                                                        </span>
                                                                    }
                                                                </p>
                                                                <div class="itemExpand">
                                                                    @if (item.hasChildren || this.itemTypeId === 1) {
                                                                        <button
                                                                            (click)="expanded(item)"
                                                                            class="svg--expand-collapse c-button--flat c-margin-left--quarter"
                                                                            id="button{{ item.type_id }}"
                                                                            attr.aria-label="Expand or Collapse {{
                                                                                item.type_name
                                                                            }}"
                                                                        >
                                                                            @if (!item.expanded) {
                                                                                <svg class="c-svgIcon" role="img">
                                                                                    <title>Expand</title>
                                                                                    <use
                                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                                        xlink:href="./resources/typescript/assets/css-compiled/images/sprite.svg#chevron-down"
                                                                                    ></use>
                                                                                </svg>
                                                                            }
                                                                            @if (item.expanded) {
                                                                                <svg class="c-svgIcon" role="img">
                                                                                    <title>Collapse</title>
                                                                                    <use
                                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                                        xlink:href="./resources/typescript/assets/css-compiled/images/sprite.svg#chevron-up"
                                                                                    ></use>
                                                                                </svg>
                                                                            }
                                                                        </button>
                                                                    }
                                                                    <button
                                                                        (click)="
                                                                            this.goToDetail(item, item.type_id, 'C')
                                                                        "
                                                                        class="c-textButton edit-icon-{{ idx }}"
                                                                        attr.aria-label="Edit {{ item.type_name }}"
                                                                        [ngClass]="{ editTrue: item.expanded }"
                                                                    >
                                                                        Edit
                                                                        {{
                                                                            item.cabinet_id ? "Cabinet" : "Cabinet Type"
                                                                        }}
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        }
                                                        @case ("F") {
                                                            <div
                                                                class="hierarchy--wrapper"
                                                                (mouseover)="showEditOnHover(idx)"
                                                                (mouseleave)="removeEditOnHover(idx, item.expanded)"
                                                                (focusin)="showEditOnHover(idx)"
                                                                (focusout)="removeEditOnHover(idx, item.expanded)"
                                                                [attr.aria-label]="item.type_name"
                                                                tabindex="0"
                                                            >
                                                                @if (
                                                                    this.orgItemTypeId === 3 && item.parent_id !== ""
                                                                ) {
                                                                    <span>
                                                                        <s25-ng-shift-selectable-checkbox
                                                                            [id]="'item-' + idx"
                                                                            [index]="idx"
                                                                            [group]="'folder'"
                                                                            [modelValue]="false"
                                                                            (modelValueChange)="
                                                                                this.onCheckBoxChange(item, $event)
                                                                            "
                                                                        ></s25-ng-shift-selectable-checkbox>
                                                                    </span>
                                                                }
                                                                @if (item.parent_id !== "") {
                                                                    <p
                                                                        class="buttonText"
                                                                        (click)="this.expanded(item)"
                                                                        (keydown.enter)="expanded(item)"
                                                                        id="textFocus{{ item.type_id }}"
                                                                        tabindex="0"
                                                                    >
                                                                        <span class="svg--icon">
                                                                            <svg class="c-svgIcon" role="img">
                                                                                <title>Folder</title>
                                                                                <use
                                                                                    xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                                    xlink:href="./resources/typescript/assets/css-compiled/images/sprite.svg#event_structure--folder-default"
                                                                                ></use>
                                                                            </svg>
                                                                        </span>
                                                                        <span>{{ item.type_name }}</span>
                                                                        @if (item.defn_state === 0 && !item.event_id) {
                                                                            <span class="not_active c-margin-left--half"
                                                                                >[Not Active]
                                                                            </span>
                                                                        }
                                                                    </p>
                                                                }
                                                                <div class="itemExpand">
                                                                    @if (item.folder && item.folder.length > 0) {
                                                                        <button
                                                                            (click)="expanded(item)"
                                                                            class="svg--expand-collapse c-button--flat c-margin-left--quarter"
                                                                            id="button{{ item.type_id }}"
                                                                            attr.aria-label="Expand or Collapse {{
                                                                                item.type_name
                                                                            }}"
                                                                        >
                                                                            @if (!item.expanded) {
                                                                                <svg class="c-svgIcon" role="img">
                                                                                    <title>Expand</title>
                                                                                    <use
                                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                                        xlink:href="./resources/typescript/assets/css-compiled/images/sprite.svg#chevron-down"
                                                                                    ></use>
                                                                                </svg>
                                                                            }
                                                                            @if (item.expanded) {
                                                                                <svg class="c-svgIcon" role="img">
                                                                                    <title>Collapse</title>
                                                                                    <use
                                                                                        xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                                        xlink:href="./resources/typescript/assets/css-compiled/images/sprite.svg#chevron-up"
                                                                                    ></use>
                                                                                </svg>
                                                                            }
                                                                        </button>
                                                                    }
                                                                    <button
                                                                        (click)="
                                                                            this.goToDetail(item, item.type_id, 'F')
                                                                        "
                                                                        class="c-textButton edit-icon-{{ idx }}"
                                                                        attr.aria-label="Edit {{ item.type_name }}"
                                                                        [ngClass]="{ editTrue: item.expanded }"
                                                                    >
                                                                        Edit
                                                                        {{ item.event_id ? "Folder" : "Folder Type" }}
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        }
                                                        @case ("E") {
                                                            <div
                                                                class="hierarchy--wrapper"
                                                                (mouseover)="showEditOnHover(idx)"
                                                                (mouseleave)="removeEditOnHover(idx, item.expanded)"
                                                                (focusin)="showEditOnHover(idx)"
                                                                (focusout)="removeEditOnHover(idx, item.expanded)"
                                                                [attr.aria-label]="item.type_name"
                                                                tabindex="0"
                                                            >
                                                                <span>
                                                                    <s25-ng-shift-selectable-checkbox
                                                                        [id]="'item-' + idx"
                                                                        [index]="idx"
                                                                        [modelValue]="false"
                                                                        [group]="'eventType'"
                                                                        (modelValueChange)="
                                                                            this.onCheckBoxChange(item, $event)
                                                                        "
                                                                    ></s25-ng-shift-selectable-checkbox>
                                                                </span>
                                                                <p
                                                                    class="buttonText"
                                                                    (click)="this.expanded(item)"
                                                                    (keydown.enter)="expanded(item)"
                                                                    id="textFocus{{ item.type_id }}"
                                                                    tabindex="0"
                                                                >
                                                                    <span class="svg--icon">
                                                                        <svg class="c-svgIcon" role="img">
                                                                            <title>Event Type</title>
                                                                            <use
                                                                                xmlns:xlink="http://www.w3.org/1999/xlink"
                                                                                xlink:href="./resources/typescript/assets/css-compiled/images/sprite.svg#documents--blank"
                                                                            ></use>
                                                                        </svg>
                                                                    </span>
                                                                    <span>{{ item.type_name }}</span>
                                                                    @if (item.defn_state === 0) {
                                                                        <span class="not_active c-margin-left--half"
                                                                            >[Not Active]
                                                                        </span>
                                                                    }
                                                                </p>
                                                                <div class="itemExpand">
                                                                    <button
                                                                        (click)="
                                                                            this.goToDetail(item, item.type_id, 'E')
                                                                        "
                                                                        class="c-textButton edit-icon-{{ idx }}"
                                                                        attr.aria-label="Edit {{ item.type_name }}"
                                                                        [ngClass]="{ editTrue: item.expanded }"
                                                                    >
                                                                        Edit Event Type
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        }
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    }
                                }
                                @case ("E") {
                                    <s25-ng-event-type
                                        [isEdit]="this.isEdit"
                                        [eventTypeId]="this.itemId"
                                        [itemTypeId]="this.orgItemTypeId"
                                        [isCopy]="this.isCopy"
                                    ></s25-ng-event-type>
                                    @if (!this.resetType) {
                                        <div class="c-displayBlock c-margin-top--single c-margin-bottom--half">
                                            <button
                                                class="aw-button aw-button--outline c-margin-top--single c-margin-right--quarter"
                                                (click)="this.toggleType()"
                                            >
                                                View in Hierarchy
                                            </button>
                                            <a href="{{ this.eventTypeListUrl }}"
                                                ><button
                                                    class="aw-button aw-button--outline c-margin-top--single c-margin-right--quarter"
                                                >
                                                    View in List
                                                </button></a
                                            >
                                        </div>
                                    }
                                }
                                @case ("C") {
                                    @if (this.itemTypeId === 1) {
                                        <s25-ng-cabinet-folder
                                            [isEdit]="this.isEdit"
                                            [(type)]="this.type"
                                            [typeId]="this.itemId"
                                        ></s25-ng-cabinet-folder>
                                    }
                                    @if (this.itemTypeId === 2 && this.itemTypeId != 1) {
                                        <s25-ng-adv-cabinet-folder
                                            [itemTypeId]="this.itemTypeId"
                                            [isEdit]="this.isEdit"
                                            [(type)]="this.type"
                                            [typeId]="this.itemId"
                                            [isCopy]="this.isCopy"
                                        ></s25-ng-adv-cabinet-folder>
                                    }
                                    @if (!this.resetType) {
                                        <div class="c-displayBlock c-margin-top--single c-margin-bottom--half">
                                            <button
                                                class="aw-button aw-button--outline c-margin-top--single c-margin-right--quarter"
                                                (click)="this.toggleType()"
                                            >
                                                View in Hierarchy
                                            </button>
                                            <a href="{{ this.eventTypeListUrl }}"
                                                ><button
                                                    class="aw-button aw-button--outline c-margin-top--single c-margin-right--quarter"
                                                >
                                                    View in List
                                                </button></a
                                            >
                                        </div>
                                    }
                                }
                                @case ("F") {
                                    @if (this.itemTypeId === 1 && this.itemTypeId === this.orgItemTypeId) {
                                        <s25-ng-cabinet-folder
                                            [isEdit]="this.isEdit"
                                            [(type)]="this.type"
                                            [typeId]="this.itemId"
                                        ></s25-ng-cabinet-folder>
                                    }
                                    @if (this.itemTypeId === 3) {
                                        <s25-ng-adv-cabinet-folder
                                            [itemTypeId]="this.itemTypeId"
                                            [isEdit]="this.isEdit"
                                            [(type)]="this.type"
                                            [typeId]="this.itemId"
                                            [isCopy]="this.isCopy"
                                        ></s25-ng-adv-cabinet-folder>
                                    }
                                    @if (!this.resetType) {
                                        <div class="c-displayBlock c-margin-top--single c-margin-bottom--half">
                                            <button
                                                class="aw-button aw-button--outline c-margin-top--single c-margin-right--quarter"
                                                (click)="this.toggleType()"
                                            >
                                                View in Hierarchy
                                            </button>
                                            <a href="{{ this.eventTypeListUrl }}"
                                                ><button
                                                    class="aw-button aw-button--outline c-margin-top--single c-margin-right--quarter"
                                                >
                                                    View in List
                                                </button></a
                                            >
                                        </div>
                                    }
                                }
                            }
                        </div>
                        <!--TODO: Do you think there is a way to make this button text dynamic? 'Return to Hierarchy' if they came from Hierarchy; 'View in Hierarchy' if they came from list? And same for list button. -->
                    </div>
                }
            </div>
        }
        @if (this.bulkEditAction) {
            <div>
                <s25-ng-bulk-edit
                    [itemTypeId]="this.itemTypeId === 1 ? 7 : this.itemTypeId === 2 ? 11 : 12"
                    [itemIds]="this.bulkEditItems"
                    [itemTypeName]="this.itemTypeId === 1 ? 'Event Type' : this.itemTypeId === 2 ? 'Cabinet' : 'Folder'"
                    idsOnly="true"
                    [chosenModels]="this.chosenModels"
                ></s25-ng-bulk-edit>
                <button
                    class="aw-button aw-button--outline c-margin-top--single c-margin-right--quarter"
                    (click)="returnToHierarchy()"
                >
                    Return to Hierarchy
                </button>
            </div>
        }
    `,
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class S25EventTypeHierarchyComponent implements OnInit, OnDestroy {
    init = false;
    type = "view";
    typeId = 1;
    allTypes: any;
    cabinets: any;
    parentId = 0;
    isEdit = false;
    expandedAll: boolean;
    @Input() resetType?: boolean = false;
    isFromList?: boolean = false;
    listToTree: any;
    items: any[];
    itemId: any;
    displayHierarchy: boolean = false;
    hierarchyUrl = HierarchyUtil.getCompUrl("hierarchy");
    eventTypeListUrl = HierarchyUtil.getCompUrl("eventTypeList");
    itemTypeId: number = 1; //1 = eventType, 2 = cabinet, 3 = folder --  re-use hierarchy via event type hierarchy
    mappingItem: any;
    orgItemTypeId: number = 1;
    promiseArr: any = [];
    dropdownTypes = [
        { itemId: 1, itemName: "Event Type Hierarchy" },
        { itemId: 2, itemName: "Cabinet Hierarchy" },
        { itemId: 3, itemName: "Folder Hierarchy" },
    ];
    mode: any = "view";
    cabinetsListData: any = [];
    bulkEditItems: any = [];
    bulkEditAction: boolean = false;
    chosenModels: any = [];
    listToFolderTreeInit: boolean = false;
    getDataDate: any = new Date().getFullYear() - 1 + "1231"; //'20171231';

    modeDates = [
        { itemId: 1, itemName: "Current Year Data" },
        { itemId: 5, itemName: "5 Years Data" },
        { itemId: 7, itemName: "7 Years Data" },
        { itemId: 10, itemName: "10 Years Data" },
        { itemId: 15, itemName: "15 Years Data" },
        { itemId: 20, itemName: "20 Years Data" },
    ];

    selectedDate = 0;
    isCopy: boolean = false;
    openFirstItem: boolean = false;

    constructor(
        private elementRef: ElementRef,
        private cd: ChangeDetectorRef,
        private renderer: Renderer2,
        private location: Location,
        private zone: NgZone,
    ) {}

    changeDataDate(e: any) {
        this.getDataDate = new Date().getFullYear() - e.target.value + "1231";
        this.selectedDate = parseInt(e.target.value) || 1;
        this.ngOnInit(this.selectedDate);
        let hierarchyUrl =
            HierarchyUtil.getCompUrl("hierarchy", this.selectedDate) + "/" + this.itemTypeId + "/view/F/" + this.itemId;
        window.location.assign(hierarchyUrl); // assign url
    }

    ngOnInit(e?: any) {
        this.init = false;
        this.listToFolderTreeInit = false;
        this.elementRef.nativeElement.angBridge = this; //bridge to AngularJS; used for AngJS to set model values and call setter fns
        S25LoadingApi.init(this.elementRef.nativeElement);

        let data = HierarchyUtil.getUrlParam();
        data && data.getUrlMode ? (this.mode = data.getUrlMode) : (this.mode = "view");
        this.mode === "copy" ? (this.isCopy = true) : (this.isCopy = false);

        if (data && data.getUrlDate && data.getUrlType === "F" && !e) {
            this.getDataDate = new Date().getFullYear() - data.getUrlDate + "1231";
            this.selectedDate = parseInt(data.getUrlDate);
        } else if (e) {
            this.getDataDate = new Date().getFullYear() - e + "1231";
            this.selectedDate = e;
        }

        if (this.resetType) {
            this.type = "view";
            if (data.getUrlTypeId) {
                this.itemId = parseInt(data.getUrlTypeId);
                this.displayHierarchy = true;
            }
        } else if (
            data &&
            parseInt(data.getUrlTypeId) &&
            (data.getUrlType === "E" || data.getUrlType === "C" || data.getUrlType === "F") &&
            (data.getUrlMode === "edit" || data.getUrlMode === "copy")
        ) {
            this.type = data.getUrlType;
            this.itemId = parseInt(data.getUrlTypeId);
            this.itemTypeId = parseInt(data.getUrlItemTypeId);
            this.isEdit = true;
        } else if (
            data &&
            parseInt(data.getUrlTypeId) &&
            (data.getUrlType === "E" || data.getUrlType === "C" || data.getUrlType === "F") &&
            data.getUrlMode === "view"
        ) {
            this.type = "view";
            switch (data.getUrlType) {
                case "E":
                    this.typeId = 1;
                    break;
                case "C":
                    this.typeId = 2;
                    break;
                case "F":
                    this.typeId = 3;
                    break;
                default:
            }
            this.itemId = parseInt(data.getUrlTypeId);
            this.itemTypeId = parseInt(data.getUrlItemTypeId);
            this.displayHierarchy = true;
        } else if (
            data &&
            parseInt(data.getUrlTypeId) === 0 &&
            (data.getUrlType === "E" || data.getUrlType === "C" || data.getUrlType === "F") &&
            data.getUrlMode === "new"
        ) {
            this.type = data.getUrlType;
            this.itemTypeId = parseInt(data.getUrlItemTypeId);
            this.isEdit = false;
        } else if (data && data.getUrlTypeId === "hierarchy" && data.getUrlType === "types") {
            // ie url = !/home/system/settings/master/definitions/event/types/hierarchy
            this.type = "view";
            this.displayHierarchy = false;
            this.itemTypeId = 1;
            this.openFirstItem = true;
        }

        switch (this.type) {
            case "E":
                this.typeId = 1;
                break;
            case "C":
                this.typeId = 2;
                break;
            case "F":
                this.typeId = 3;
                break;
            default:
        }

        this.orgItemTypeId = this.itemTypeId;
        this.mappingItem = S25EventTypesMap[this.typeId];
        this.orgItemTypeId !== 1 ? this.promiseArr.push(this.getEventType()) : ""; // WS call to get top cabient level for manage  cabinet &  folder
        this.orgItemTypeId === 3 ? this.promiseArr.push(this.getCabinet()) : ""; //// if folder/itemTypeId = 3, need to need to get subset cabinet (level 2)

        this.getAllEventTypes();
        this.detectChanges();
    }

    getEventType() {
        return EventService.getAllEventType("C").then((data) => {
            data = S25Util.array.forceArray(data);
            this.allTypes = S25Util.deepCopy(data);
            this.allTypes = this.allTypes.map((i: any) => {
                i.event_id = i.type_id;
                i.event_name = i.type_name;
                i.hasChildren = true;
                i.uid = "level1_" + i.type_id;
                i.parentTempId = "level1_" + i.parent_id;
                return i;
            });
            //// if folder/itemTypeId = 3, need to need to get subset cabinet///////////////
            S25LoadingApi.destroy(this.elementRef.nativeElement);
            this.detectChanges();
        });
    } // end getEventType

    getCabinet() {
        if (this.orgItemTypeId === 3) {
            return EventService.getNodeTypeCabinet(this.getDataDate).then((data: any) => {
                data = S25Util.array.forceArray(data);
                ///// set/decorate Cabinet and folder node type, event.json doesn't have this node (re-use component via type event )
                if (data.length > 0) {
                    data = data.map((i: any) => {
                        i.node_type = "C";
                        i.type_id = i.event_id;
                        i.type_name = i.event_name;
                        i.defn_state = i.state;
                        i.parent_id = i.event_type_id;
                        i.hasChildren = true;
                        i.uid = "level2_" + i.event_id;
                        i.parentTempId = "level1_" + i.event_type_id; // cabinet parent id = event type id
                        return i;
                    });
                }
                this.allTypes = data.concat(this.allTypes); // combine  data, need to to find path (parent to children)
            });
        } ///////////////////end get subset cabinet /////////////////
    }

    getAllEventTypes() {
        return S25Util.all(this.promiseArr).then((resp) => {
            S25LoadingApi.init(this.elementRef.nativeElement);
            if (this.orgItemTypeId === 1 || this.orgItemTypeId === 2) {
                this.getDataDate = "";
            }

            return this.mappingItem.service.getItems(this.getDataDate).then((data: any) => {
                data = S25Util.array.forceArray(data);
                //ANG-3889 data need a uid, also set tempParentId (look for top level, ie Cabinet's parent is  event type Id/cabinet id, folder parent is cabinet/folder id )
                if (data.length > 0) {
                    data = data.map((i: any) => {
                        if (i) {
                            if (this.orgItemTypeId === 2) {
                                // cabinets
                                i.parent_id && i.parent_id !== ""
                                    ? (i.parentTempId = "level3_" + i.parent_id)
                                    : (i.parentTempId = "level1_" + i.event_type_id);
                                i.uid = "level3_" + i.event_id;
                            } else if (this.orgItemTypeId === 3) {
                                // folders
                                // TODO  find if subfolder, parent level is 3
                                S25Util.array.isIn(data, "event_id", i.parent_id)
                                    ? (i.parentTempId = "level3_" + i.parent_id)
                                    : (i.parentTempId = "level2_" + i.parent_id);
                                i.uid = "level3_" + i.event_id;
                            } else {
                                // even types
                                i.uid = "level3_" + i.type_id;
                                i.parentTempId = "level3_" + i.parent_id;
                            }
                        }
                        return i;
                    });
                }
                this.orgItemTypeId != 1 ? (data = data.concat(this.allTypes)) : ""; // combine cabinet/folder data to  eventype (re-use component)
                this.allTypes = data;
                ///// set/decorate  Cabinet and folder data structures silimar with evtype.json , event.json has diff data structures, to find path (parent to children)
                let nodeType = "C";
                this.orgItemTypeId === 3 ? (nodeType = "F") : "";
                if (this.orgItemTypeId !== 1 && data) {
                    if (this.allTypes.length > 0) {
                        this.allTypes = this.allTypes.map((i: any) => {
                            if (i) {
                                i.type_id = i.event_id;
                                i.type_name = i.event_name;
                                i.defn_state = i.state;
                            }
                            return i;
                        });
                    }
                }

                if (this.displayHierarchy) {
                    setTimeout(() => {
                        this.getItems("displayHierarchy");
                        this.init = true;
                        //set visual focus on element
                        let element = document.getElementById("textFocus" + this.itemId);
                        element && element.focus();
                    }, 120);
                } else {
                    setTimeout(() => {
                        this.getItems();
                        this.init = true;
                    }, 120);
                }

                S25LoadingApi.destroy(this.elementRef.nativeElement);

                this.detectChanges();
            }); //END return
        });
    }

    expandAll() {
        this.expandedAll = true;
        this.getItems("expandAll");
        this.detectChanges();
    }

    collapseAll() {
        this.expandedAll = false;
        this.items.forEach((item) => {
            this.expanded(item);
        });
        this.detectChanges();
    }

    expanded(item: any) {
        if (item.expanded && item.folder && item.folder.length) {
            //if parent or grandparent collapses, nested items collapse as well
            item.folder.forEach((child: any) => {
                child.expanded = false;
                if (child.folder && child.folder.length) {
                    child.folder.forEach((grandchild: any) => {
                        grandchild.expanded = false;
                    });
                }
            });
        }

        item.expanded = !item.expanded;

        if (this.displayHierarchy) {
            this.displayHierarchy = false;
            this.getItems("displayHierarchy");
        } else {
            this.items = HierarchyUtil.getItems(this.listToTree, null, 0);
        }

        this.detectChanges();
    }

    async getItems(action?: any) {
        let listToFolderTree = this.allTypes.sort((a: any, b: any) =>
            a.node_type < b.node_type ? 1 : b.node_type < a.node_type ? -1 : 0,
        );
        listToFolderTree = listToFolderTree.filter((i: any) => i.type_id != -1);
        if (action === "expandAll") {
            this.allTypes = listToFolderTree;
            listToFolderTree = listToFolderTree.map((type: any) => {
                type.expanded = true;
                return type;
            });
        } else if (action === "displayHierarchy") {
            let listData = S25Util.deepCopy(listToFolderTree);
            let path = HierarchyUtil.findPath(
                HierarchyUtil.listToTree(listData, { idKey: "uid", parentKey: "parentTempId", childrenKey: "folder" }),
                this.itemId,
                "",
                "type_id",
            );
            // let  path = HierarchyUtil.findPath(HierarchyUtil.listToTree(listData, {idKey: this.mappingItem.item.id, parentKey: this.mappingItem.parentKey, childrenKey: 'folder'}), this.itemId, '','type_id');
            listToFolderTree = await listToFolderTree.map((type: any) => {
                let findTypeId = path.filter((i: any) => i.type_id === type.type_id);
                if (findTypeId.length > 0) {
                    type.expanded = true;
                }
                return type;
            });
        }
        this.listToTree = HierarchyUtil.listToTree(listToFolderTree, {
            idKey: "uid",
            parentKey: "parentTempId",
            childrenKey: "folder",
        });
        //this.listToTree =  HierarchyUtil.listToTree(listToFolderTree, { idKey: this.mappingItem.item.id, parentKey: this.mappingItem.parentKey, childrenKey: 'folder'});
        this.items = await HierarchyUtil.getItems(this.listToTree, null, 0);
        this.listToFolderTreeInit = true;

        if (this.items && this.items.length !== 0) {
            if (this.openFirstItem) {
                this.expanded(this.items[0]);
                setTimeout(() => {
                    let element = document.getElementById("textFocus" + this.items[0].type_id);
                    element && element.focus();
                }, 120);
            } else if (action !== "expandAll") {
                S25Util.replaceDeep(this.items[0], { expanded: true });
            }
        }

        this.detectChanges();
    }

    goToDetail(item: any, id: any, type: any, isCopy?: any) {
        this.itemId = id;
        this.type = type;
        this.isEdit = true;
        isCopy ? (this.isCopy = true) : (this.isCopy = false);
        // following --- Cabient/Folder Hierarchy, top level cabinet need go to event type cabinet form
        if (item.event_id && !item.cabinet_id && (type === "C" || type === "F")) {
            this.itemTypeId = 1;
            this.orgItemTypeId = 2;
        } else if (item.cabinet_id && item.node_type === "C" && item.parent_id === "") {
            // sub cabinet go to cabinet Form
            this.orgItemTypeId = 2;
            this.itemTypeId = 2;
        } else {
            let urlData = HierarchyUtil.getUrlParam();
            if (parseInt(urlData.getUrlItemTypeId) === 3 && urlData.getUrlType === "F") {
                if (item.node_type === "C") {
                    this.itemTypeId = 2;
                    this.orgItemTypeId = 3;
                } else {
                    this.itemTypeId = 3;
                    this.orgItemTypeId = 3;
                }
            } else {
                this.orgItemTypeId = this.itemTypeId;
            }
        }

        let url = HierarchyUtil.getCompUrl("hierarchy", this.selectedDate);
        isCopy
            ? (url += "/" + this.itemTypeId + "/copy/" + this.type + "/" + this.itemId)
            : (url += "/" + this.itemTypeId + "/edit/" + this.type + "/" + this.itemId);
        window.location.assign(url);
    }

    toggleType() {
        let url = HierarchyUtil.getCompUrl("hierarchy", this.selectedDate);
        let tempType = "E";
        switch (this.itemTypeId) {
            case 1:
                tempType = "E";
                break;
            case 2:
                tempType = "C";
                break;
            case 3:
                tempType = "F";
                break;
            default:
        }
        if (this.itemId > 0) {
            this.isEdit = false;
            this.type = tempType;
            url += "/" + this.itemTypeId + "/view/" + tempType + "/" + this.itemId;
            window.location.assign(url);
            //this.ngOnInit();
        } else {
            this.isEdit = false;
            this.itemId = 1;
            window.location.assign(url); // assign url
            //this.ngOnInit();
        }
    }

    createNew(itemTypeId: any, e?: any) {
        e ? (this.type = e.target.value) : "";
        this.isEdit = false;
        if (itemTypeId === 1) {
            if (this.type === "view") {
                alert("Please select a type to create.");
            } else {
                this.itemId = 0;
                let url = HierarchyUtil.getCompUrl("eventType");
                url += "/new/" + this.type + "/0";
                window.location.assign(url);
            }
        } else if (itemTypeId === 2) {
            let url = HierarchyUtil.getCompUrl("cabinetAdvForm");
            url += "/new/C/0";
            window.location.assign(url);
        } else {
            let url = HierarchyUtil.getCompUrl("folderAdvForm");
            url += "/new/F/0";
            window.location.assign(url);
        }
        this.detectChanges();
    }

    returnToList() {
        this.location.back();
    }

    refresh() {
        this.expandedAll = false;
        let url = HierarchyUtil.getCompUrl("hierarchy");
        window.location.assign(url);
        this.ngOnInit();
    }

    ngOnDestroy() {
        HierarchyApi.refresh(this.elementRef.nativeElement);
        EventTypeApi.refresh(this.elementRef.nativeElement);
        CabinetFolderApi.refresh(this.elementRef.nativeElement);
    }

    goToHierarchyType(type: any) {
        switch (type.itemName) {
            case "Event Type Hierarchy":
                this.type = "E";
                this.itemTypeId = 1;
                break;
            case "Cabinet Hierarchy":
                this.type = "C";
                this.itemTypeId = 2;
                break;
            case "Folder Hierarchy":
                this.type = "F";
                this.itemTypeId = 3;
                break;
            default:
        }
        let hierarchyUrl =
            HierarchyUtil.getCompUrl("hierarchy", this.selectedDate) +
            "/" +
            this.itemTypeId +
            "/view/" +
            this.type +
            "/1";
        window.location.assign(hierarchyUrl); // assign url
    }

    detectChanges() {
        try {
            setTimeout(() => {
                this.cd && !(this.cd as ViewRef).destroyed && this.cd.detectChanges();
                //this.cd.detectChanges();
            }, 60);
        } catch (error: any) {}
    }

    onCheckBoxChange(item: any, e: any) {
        if (e) {
            this.bulkEditItems.push(item.type_id);
            this.chosenModels.push({
                itemId: item.type_id,
                itemName: item.type_name,
                itemStartDate: item.start_date,
                itemEndDate: item.end_date,
                itemParentId: item.parent_id,
                itemEventTypeId: item.event_type_id,
            });
        } else {
            this.bulkEditItems = this.bulkEditItems.filter(function (i: any) {
                return i !== item.type_id;
            });
        }
        this.detectChanges();
    }

    gotToBulkEdit() {
        this.bulkEditAction = true;
        this.detectChanges();
    }

    returnToHierarchy() {
        this.bulkEditAction = false;
        this.bulkEditItems = [];
        this.chosenModels = [];
        this.ngOnInit();
    }

    showEditOnHover(index: number) {
        const editIcon = document.querySelector(`.edit-icon-${index}`);
        editIcon.classList.add("editTrue");
    }

    removeEditOnHover(index: number, expanded: boolean) {
        const editIcon = document.querySelector(`.edit-icon-${index}`);
        if (editIcon && editIcon.classList && !expanded) {
            editIcon.classList.remove("editTrue");
        }
    }
}
