import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { S25DropdownPaginatedModule } from "../s25-dropdown/s25.dropdown.paginated.module";
import { S25EditableModule } from "../s25-editable/s25.editable.module";
import { S25ItemModule } from "../s25-item/s25.item.module";
import { S25LoadingInlineModule } from "../s25-loading/s25.loading.inline.module";
import { S25EventTypeComponent } from "./s25.event.type.component";
import { S25EventTypeHierarchyComponent } from "./s25.event.type.hierarchy.component";
import { S25MultiselectModule } from "../s25-multiselect/s25-multiselect.module";
import { S25NotificationPolicyModule } from "../s25-notification-policy/s25.notification.policy.module";
import { S25SimpleCollapseModule } from "../s25-simple-collapse/s25.simple.collapse.module";
import { S25CabinetFolderComponent } from "./s25.cabinet.folder.component";
import { S25ToggleButtonModule } from "../s25-toggle-button/s25.toggle.button.module";
import { S25ExpandedContentComponent } from "./s25.expanded.content.component";
import { S25AdvCabinetFolderComponent } from "./s25.adv.cabinet.folder.component";
import { S25DatepickerModule } from "../s25-datepicker/s25.datepicker.module";
import { S25RichTextEditorModule } from "../s25-rich-text-editor/s25.rich.text.editor.module";
import { S25DatePatternModule } from "../s25-datepattern/s25.datepattern.module";
import { S25CheckboxTemplateComponent } from "./s25.checkbox.template.component";
import { S25CheckboxModule } from "../s25-checkbox/s25.checkbox.module";
import { S25EventTypeDeleteComponent } from "./s25.event.type.delete.component";
import { S25SecurityLinkModule } from "../s25-security-link/s25.security.link.module";
import { S25BulkEditModule } from "../s25-bulk-edit/s25.bulk.edit.module";
import { S25ConstraintsModule } from "../s25-constraints/s25.constraints.module";
import { S25CreateSubfolderComponent } from "./s25.create.subfolder.component";
import { S25ModalModule } from "../s25-modal/s25.modal.module";
import { S25ShiftSelectableModule } from "../s25-shift-select/s25.shift.selectable.module";

//@author: mandy
@NgModule({
    declarations: [
        S25EventTypeComponent,
        S25EventTypeHierarchyComponent,
        S25CabinetFolderComponent,
        S25ExpandedContentComponent,
        S25AdvCabinetFolderComponent,
        S25CheckboxTemplateComponent,
        S25EventTypeDeleteComponent,
        S25CreateSubfolderComponent,
    ],
    imports: [
        CommonModule,
        S25DropdownPaginatedModule,
        S25ItemModule,
        S25EditableModule,
        S25LoadingInlineModule,
        S25MultiselectModule,
        S25NotificationPolicyModule,
        S25SimpleCollapseModule,
        FormsModule,
        S25DatepickerModule,
        S25ToggleButtonModule,
        S25RichTextEditorModule,
        S25DatePatternModule,
        S25CheckboxModule,
        S25SecurityLinkModule,
        S25BulkEditModule,
        S25ConstraintsModule,
        S25ModalModule,
        S25ShiftSelectableModule,
    ],
    providers: [
        S25EventTypeComponent,
        S25EventTypeHierarchyComponent,
        S25CabinetFolderComponent,
        S25ExpandedContentComponent,
        S25AdvCabinetFolderComponent,
        S25CheckboxTemplateComponent,
        S25EventTypeDeleteComponent,
        S25CreateSubfolderComponent,
    ],
    exports: [
        S25EventTypeComponent,
        S25EventTypeHierarchyComponent,
        S25CabinetFolderComponent,
        S25ExpandedContentComponent,
        S25AdvCabinetFolderComponent,
        S25CheckboxTemplateComponent,
        S25EventTypeDeleteComponent,
        S25CreateSubfolderComponent,
    ],
})
export class S25EventTypeHierarchyModule {
    constructor() {}
}
